// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);

.News {
    background-color: white;

    .wrapper {
        display: flex;
        flex-direction: column;

        @media (max-width:767px) {
            padding-top: 25vw;
        }

        .sub-title {
            color: $primary;
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(3, 3fr);

            @media (max-width:767px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .new_card {
                display: flex;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                text-decoration: none;
                flex-direction: column;
                backdrop-filter: blur(10px);
                border: solid 1px rgb(221, 221, 221);

                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                }

                .img {
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 15vw;
                    min-height: 15vw;
                    object-fit: cover;
                    background-color: rgb(225, 225, 225);

                    @media (max-width:767px) {
                        max-height: 50vw;
                        min-height: 50vw;
                    }
                }

                .text {
                    color: $dark;
                }

                .date {
                    color: $success;
                }

                .min-text {
                    opacity: 0.7;
                    color: $dark;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    .individual {
        padding-top: 14vw;

        @media (max-width:767px) {
            padding-top: 28vw;
        }
    }
}