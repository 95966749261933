// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$green: #4ab40b;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.GalleriesItem {
    .c1 {
        display: flex;
        min-height: 100vh;
        position: relative;
        padding-right: 5vw;
        padding-bottom: 5vw;
        flex-direction: column;
        justify-content: flex-end;

        @media (max-width:767px) {
            min-height: 350px;
        }
    }

    .c2 {
        display: flex;
        flex-direction: column;
        padding-bottom: 0 !important;

        .title {
            font-weight: 700;
            text-align: center;
        }

        .images {
            display: flex;
            flex-direction: column;

            .img {
                max-width: 100%;
                min-width: 100%;
                max-height: 60vw;
                min-height: 60vw;
                object-fit: contain;

                @media (max-width:767px) {
                    max-height: 80vw;
                    min-height: 80vw;
                }
            }
        }

        .big-text {
            width: 100%;
            text-align: left;
        }
    }
}