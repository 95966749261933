// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);

.Footer {
    display: flex;
    flex-direction: column;
    padding: 2vw 5vw !important;
    background: linear-gradient(25deg, #27303F 35%, #0A3969 60%);

    @media (max-width:767px) {
        padding: 3vw 5vw !important;
    }

    .top_footer {
        display: flex;
        padding: 3vw 5vw;
        align-items: center;
        justify-content: space-between;

        @media (max-width:767px) {
            gap: 8vw;
            padding: 3vw 0;
            flex-direction: column;
            align-items: flex-start;
        }

        .left {
            width: 62%;
            display: flex;
            flex-direction: column;

            @media (max-width:767px) {
                width: 100%;
            }

            .tools {
                display: grid;
                grid-template-columns: repeat(3, 3fr);

                @media (max-width:767px) {
                    gap: 3vw;
                }

                .tool {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .tool_title {
                        color: $light;
                        font-weight: 600;

                        @media (max-width:767px) {
                            font-weight: 400;
                            font-size: 2.7vw;
                            margin-bottom: -2vw;
                        }
                    }

                    .min-text {
                        opacity: 0.7;
                        color: $light;

                        &:hover {
                            text-decoration: underline;
                        }

                        @media (max-width:767px) {
                            font-size: 2.7vw;
                        }
                    }
                }
            }

            .tegs {
                display: flex;
                flex-direction: column;

                .big-text {
                    gap: 13px;
                    color: $light;
                    display: flex;
                    align-items: center;

                    span {
                        width: 85%;
                        height: 1px;
                        opacity: 0.5;
                        background-color: $light;
                    }
                }

                .bars {
                    display: flex;
                    flex-wrap: wrap;

                    .bar {
                        opacity: 0.7;
                        color: $light;
                        padding: 0.3vw 0.5vw;
                        border: solid 1px $light;

                        @media (max-width:767px) {
                            padding: 0.5vw 1.5vw;
                        }

                        &:hover {
                            color: $success;
                            border-color: $success;
                        }
                    }
                }
            }
        }

        .right {
            width: 30%;
            display: flex;
            flex-direction: column;
            border: solid 1px #a4cd0072;

            @media (max-width:767px) {
                width: 100%;
            }

            .selection {
                border: none;
                outline: none;
                cursor: pointer;
                padding-bottom: 3px;
                background-color: transparent;
                border-bottom: solid 1px rgba(255, 255, 255, 0.492);

                option {
                    color: $dark;
                }
            }

            .childs {
                display: flex;
                flex-direction: column;
            }

            .text {
                opacity: 0.8;
                color: $light;
            }

            .cont {
                color: $light;
                font-weight: 600;
            }

            .email {
                a {
                    color: $success;
                }
            }

            .tel {
                a {
                    color: $light;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .bottom_footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:767px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .left {
            display: flex;
            align-items: center;

            .logo {
                width: 2.5vw;

                @media (max-width:767px) {
                    width: 7vw;
                }

                .img {
                    max-width: 100%;
                    min-width: 100%;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                }
            }

            .text {
                color: $light;
                font-weight: 600;
            }
        }

        .right {
            opacity: 0.7;
            color: $light;
        }
    }
}