.BottomPagination {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
            list-style: none;

            a {
                display: flex;
                font-size: 1vw;
                cursor: pointer;
                font-weight: 400;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                padding: 0.2vw 0.7vw;
                text-decoration: none;
                background-color: white;
                border: solid 1px #7f8b9f;
                font-family: 'Poppins', sans-serif;

                &:hover {
                    color: white;
                    background-color: #7f8b9f;
                }

                @media (max-width:767px) {
                    font-size: 3.3vw;
                    border-radius: 3px;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    -ms-border-radius: 3px;
                    -o-border-radius: 3px;
                    padding: 0.5vw 1.5vw;
                }
            }
        }

        .selected {
            a {
                color: white;
                border-color: #576274;
                background-color: #576274;
            }
        }

        .previous,
        .next {
            a {
                color: white;
                border-color: #3BA2D7;
                background-color: #3BA2D7;

                &:hover {
                    color: white;
                    border-color: black;
                    background-color: black;
                }
            }
        }
    }
}