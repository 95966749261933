// colors //

$dark: black;
$light: white;
$success: #a4cd00;

.Header {
    background-color: $dark;

    .head_img {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 90%;
        object-fit: cover;
        position: absolute;
        filter: brightness(0.8);
        -webkit-filter: brightness(0.8);
    }

    .side_btn {
        top: 18vw;
        z-index: 2;
        right: -5.5vw;
        color: $light;
        font-weight: 600;
        padding: 1vw 2vw;
        position: absolute;
        text-decoration: none;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        background-color: $success;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:hover {
            color: $success;
            background-color: $light;
        }

        @media (max-width:767px) {
            top: 60vw;
            right: -15vw;
            font-weight: 400;
        }
    }

    .wrapper {
        display: flex;
        padding-top: 6vw;
        flex-direction: column;

        @media (max-width:767px) {
            padding-top: 20vw;
        }

        .head_title {
            z-index: 2;
            font-size: 20vw;
            position: sticky;
            text-align: center;
            text-transform: uppercase;
            background: -webkit-linear-gradient(-100deg, $success 30%, #4dced2 80%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width:767px) {
                font-size: 25vw;
            }
        }

        .head_sub_title {
            z-index: 2;
            font-size: 10vw;
            text-align: end;
            font-weight: 500;
            position: sticky;
            margin-top: -10vw;
            font-style: italic;
            color: transparent;
            background-clip: text;
            text-transform: uppercase;
            -webkit-background-clip: text;
            background-image: url('../../assets/images/flag.jpg');
            background-size: 100% 80%;
            background-position: center;
            background-repeat: no-repeat;

            @media (max-width:767px) {
                font-size: 13vw;
                margin-top: -13vw;
                padding-right: 2vw;
            }
        }

        .news {
            z-index: 2;
            display: flex;
            position: sticky;
            margin-top: 10vw;
            flex-direction: column;

            @media (max-width:767px) {
                margin-top: 15vw;
            }

            .sub-title {
                width: 100%;
                display: flex;
                color: $light;
                align-items: center;

                span {
                    width: 100%;
                    height: 2px;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;
                    background-color: $light;

                    @media(max-width:767px) {
                        height: 1px;
                    }
                }
            }

            .mySwiper {
                width: 100%;
                padding: 2vw 0;

                @media (max-width:767px) {
                    padding: 4vw 0;
                }

                .new_card {
                    display: flex;
                    transition: 0.3s;
                    -webkit-transition: 0.3s;
                    -moz-transition: 0.3s;
                    -ms-transition: 0.3s;
                    -o-transition: 0.3s;
                    text-decoration: none;
                    flex-direction: column;
                    backdrop-filter: blur(10px);
                    background-color: rgba(255, 255, 255, 0.212);
                    border-top: solid 1px rgba(255, 255, 255, 0.525);
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                    &:hover {
                        transform: translateY(-15px);
                        -webkit-transform: translateY(-15px);
                        -moz-transform: translateY(-15px);
                        -ms-transform: translateY(-15px);
                        -o-transform: translateY(-15px);
                    }

                    .date {
                        color: $success;
                        font-weight: 600;
                    }

                    .name {
                        color: $light;
                        font-weight: 700;
                    }

                    .desc {
                        opacity: 0.8;
                        color: $light;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }

                    .explore {
                        color: $light;
                        font-weight: 600;
                        text-align: right;
                    }
                }
            }
        }
    }
}