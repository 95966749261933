// colors //

$dark: black;
$light: white;
$success: #a4cd00;

.ContactModal {
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.834);
    backdrop-filter: blur(13px);

    .close_icon {
        top: 3vw;
        right: 4vw;
        width: 2.3vw;
        height: 2.3vw;
        display: flex;
        cursor: pointer;
        stroke: $success;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        position: absolute;
        align-items: center;
        justify-content: center;

        &:hover {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }

        @media (max-width:767px) {
            top: 5vw;
            right: 6vw;
            width: 4.5vw;
            height: 4.5vw;
        }
    }

    .forms {
        width: 40%;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (max-width:767px) {
            width: 80%;
        }

        .title {
            color: $success;
            text-align: center;
        }

        .input {
            width: 100%;
            border: none;
            outline: none;
            color: $light;
            text-align: center;
            background-color: transparent;
            border-bottom: solid 1px $success;

            &:focus {
                border-color: $light;
            }

            &::placeholder {
                color: rgba(255, 255, 255, 0.838);
            }
        }

        .input::-webkit-inner-spin-button,
        .input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="number"] {
            appearance: 0;
            -moz-appearance: 0;
        }

        select {
            border: none;
            outline: none;
            cursor: pointer;
            text-align: center;
            background-color: transparent;
            color: rgba(255, 255, 255, 0.838);

            option {
                color: $dark;
            }
        }

        .send_btn {
            border: none;
            color: $light;
            padding: 0.8vw 5vw;
            border-radius: 70px;
            -webkit-border-radius: 70px;
            -moz-border-radius: 70px;
            -ms-border-radius: 70px;
            -o-border-radius: 70px;
            background-color: $success;

            @media (max-width:767px) {
                padding: 1.3vw 8vw;
                margin-top: 4vw;
            }
        }
    }
}