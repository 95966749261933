// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.NationalTeam {
    display: flex;
    flex-direction: column;

    .title {
        font-weight: 700;
        text-align: center;
    }

    .filters {
        display: flex;
        align-items: center;
        justify-content: center;

        .filter_btn {
            border: none;
            font-weight: 600;
            padding: 0.5vw 2vw;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -ms-border-radius: 50px;
            -o-border-radius: 50px;
        }

        .active_btn {
            color: $light;
            background-color: $success;
        }
    }

    .athletes {
        display: grid;
        padding: 0 17vw;
        position: relative;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(3, 3fr);

        @media (max-width:767px) {
            padding: 0 5vw;
            grid-template-columns: repeat(1, 1fr);
        }

        .athlete {
            height: 14vw;
            display: flex;
            overflow: hidden;
            position: relative;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            background-color: $light;
            border: solid 1px $succes-light;

            &:hover {
                .cont {
                    opacity: 1;
                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                    -o-transform: scale(1);
                }
            }

            @media (max-width:767px) {
                height: 80vw;
                overflow: inherit;
                margin-bottom: 11vw;
            }

            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
            }

            .cont {
                opacity: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                position: absolute;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                transform: scale(0);
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                -ms-transform: scale(0);
                -o-transform: scale(0);
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.459);

                @media (max-width:767px) {
                    opacity: 1;
                    bottom: -9vw;
                    height: auto;
                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                    -o-transform: scale(1);
                    background-color: transparent;
                }

                .text {
                    color: $light;
                    text-align: center;
                    text-transform: uppercase;

                    @media (max-width:767px) {
                        color: $dark;
                        font-weight: 600;
                    }
                }
            }
        }

        .category {
            left: 0;
            display: flex;
            position: absolute;
            flex-direction: column;

            @media (max-width:767px) {
                position: relative;
                margin-bottom: 2vw;
                flex-direction: row;
                justify-content: center;
            }

            .link {
                opacity: 0.7;
                display: flex;
                cursor: pointer;
                font-weight: 600;
                align-items: center;

                &::before {
                    width: 1vw;
                    height: 1vw;
                    content: "";
                    margin-right: 1vw;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    @media (max-width:767px) {
                        width: 3vw;
                        height: 3vw;
                        margin-right: 2vw;
                    }
                }
            }

            .active_link {
                opacity: 1;

                &::before {
                    background-color: $success;
                }
            }
        }
    }
}