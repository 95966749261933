// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.SportSmenItem {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }
    }

    .c2 {
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            font-weight: 700;
            text-align: center;
        }

        .big-text {
            width: 100%;
            font-weight: 600;
            text-transform: uppercase;
        }

        .infos {
            width: 100%;
            display: grid;
            align-items: center;
            grid-template-columns: repeat(3, 3fr);

            @media (max-width:767px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .img {
                max-width: 100%;
                min-width: 100%;
                // max-height: 23vw;
                // min-height: 23vw;
                object-fit: cover;
                border: solid 1px $secondary_light;
                background-color: $secondary_light;

                @media (max-width:767px) {
                    max-height: 70vw;
                    min-height: 70vw;
                }

                transition: postion;
                -webkit-transition: postion;
                -moz-transition: postion;
                -ms-transition: postion;
                -o-transition: postion;
            }

            .texts {
                display: flex;
                flex-direction: column;

                .big-text {
                    font-weight: 600;
                    color: var(red, orange);
                    text-transform: uppercase;
                }

                .medals {
                    display: flex;

                    @media (max-width:767px) {
                        gap: 5vw;
                    }

                    .medal {
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        @media (max-width:767px) {
                            flex-direction: row;
                        }

                        .medal_img {
                            max-width: 3vw;
                            min-width: 3vw;

                            @media (max-width:767px) {
                                max-width: 9vw;
                                min-width: 9vw;
                            }
                        }

                        .text {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .athlete_table {
            width: 100%;

            @media (max-width:767px) {
                overflow-x: auto;
            }

            table {
                width: 100%;

                @media (max-width:767px) {
                    width: 180vw;
                }

                thead {
                    background-color: #3ba3d74d;
                }

                tbody {
                    tr {
                        td {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}