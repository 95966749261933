// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.FullCalendar {
    right: 0;
    top: 12vw;
    z-index: 4;
    width: 4vw;
    height: 18vw;
    overflow: hidden;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    position: absolute;

    @media (max-width:767px) {
        top: 25vw;
        width: 10vw;
        height: 65vw;
    }

    .calendar {
        width: 20vw;
        right: -20vw;
        display: flex;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        position: absolute;
        padding-bottom: 3vw;
        align-items: center;
        flex-direction: column;
        background-color: #fbffeb;

        @media (max-width:767px) {
            width: 80vw;
            right: -80vw;
            padding-bottom: 6vw;
        }

        .show_btn {
            top: 6.25vw;
            left: -8.8vw;
            border: none;
            cursor: pointer;
            position: absolute;
            color: $light !important;
            transform: rotate(-90deg);
            font-weight: 400 !important;
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            background-color: #809c12db;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:hover {
                background-color: $light;
                color: $success !important;
            }

            @media (max-width:767px) {
                top: 26vw;
                left: -34vw;
            }
        }

        .Calendar {
            width: 20vw;
            max-width: 20vw;
            min-width: 20vw;
            box-shadow: none;
            background-color: #fbffeb;
            font-family: 'Poppins', sans-serif;

            @media (max-width:767px) {
                width: 80vw;
                max-width: 80vw;
                min-width: 80vw;
            }

            .Calendar__day {
                max-width: 2.2vw;
                min-width: 2.2vw;
                max-height: 2.2vw;
                min-height: 2.2vw;

                @media (max-width:767px) {
                    max-width: 9vw;
                    min-width: 9vw;
                    max-height: 9vw;
                    min-height: 9vw;
                }
            }
        }

        .text {
            color: #84a936;
        }

        .text,
        .min-text {
            width: 75%;
            font-weight: 600;
        }

        .list_btn {
            width: 75%;
            border: none;
            color: $light;
            font-weight: 500;
            background-color: #84a936;
        }

        .badges {
            width: 75%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;

            .badge {
                width: auto;
                display: flex;
                color: $light;
                font-weight: 400;
                padding: 0.1vw 0.6vw;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                align-items: center;
                justify-content: center;
                background-color: $success;

                &:nth-child(2) {
                    background-color: $pink;
                }

                &:nth-child(3) {
                    background-color: $info;
                }

                &:nth-child(4) {
                    background-color: $dodgerblue;
                }

                @media (max-width:767px) {
                    padding: 0.5vw 2vw;
                }
            }
        }
    }

    .show_calendar {
        right: 0;
    }
}

.FullHeight {
    width: 24vw;
    height: 100vw;

    @media (max-width:767px) {
        width: 100vw;
        height: 200vw;
    }
}