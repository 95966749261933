$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);

.OurLife {
    .glass_img {
        right: 0;
        width: 27%;
        bottom: 5vw;
        position: absolute;

        @media (max-width:767px) {
            width: 38%;
        }
    }

    .wrapper {
        z-index: 2;
        display: grid;
        position: sticky;
        padding: 5vw 20vw;
        grid-template-columns: repeat(3, 3fr);

        @media (max-width:767px) {
            padding: 5vw;
            grid-template-columns: repeat(2, 2fr);
        }

        .img_cont {
            width: 100%;
            height: 24vw;
            display: flex;
            overflow: hidden;
            align-items: center;
            text-decoration: none;
            justify-content: center;

            &:hover {
                .img {
                    transform: scale(1.1);
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    filter: brightness(1);
                    -webkit-filter: brightness(1);
                }

                .eye_icon {
                    opacity: 1;
                }
            }

            @media (max-width:767px) {
                height: 55vw;
            }

            .img {
                cursor: pointer;
                max-width: 100%;
                min-width: 100%;
                max-height: 100%;
                min-height: 100%;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                object-fit: cover;
                filter: brightness(0.8);
                background-color: $success;
                -webkit-filter: brightness(0.8);
            }

            .eye_icon {
                opacity: 0;
                z-index: 2;
                width: 4vw;
                height: 4vw;
                display: flex;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                position: absolute;
                align-items: center;
                justify-content: center;

                @media (max-width:767px) {
                    width: 10vw;
                    height: 10vw;
                }
            }

            .socials {
                width: 18vw;
                height: 18vw;
                display: flex;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: #a4cd0098;

                @media (max-width:767px) {
                    gap: 3vw;
                    width: 40vw;
                    height: 40vw;
                }

                .text {
                    color: $light;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                .icons {
                    display: flex;
                    align-items: center;

                    .icon {
                        fill: $light;
                        width: 2.5vw;
                        height: 2.5vw;
                        cursor: pointer;
                        transition: 0.3s;
                        -webkit-transition: 0.3s;
                        -moz-transition: 0.3s;
                        -ms-transition: 0.3s;
                        -o-transition: 0.3s;

                        &:hover {
                            transform: rotate(360deg) scale(1.3);
                            -webkit-transform: rotate(360deg) scale(1.3);
                            -moz-transform: rotate(360deg) scale(1.3);
                            -ms-transform: rotate(360deg) scale(1.3);
                            -o-transform: rotate(360deg) scale(1.3);
                        }

                        @media (max-width:767px) {
                            width: 6vw;
                            height: 6vw;
                        }
                    }
                }

                .media_btn {
                    display: flex;
                    color: $light;
                    font-weight: 600;
                    border-radius: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    -ms-border-radius: 50px;
                    -o-border-radius: 50px;
                    padding: 0.5vw 1.8vw;
                    border: solid 1px $light;
                    text-transform: uppercase;
                    background-color: rgba(255, 255, 255, 0.249);

                    &:hover {
                        color: $success;
                        background-color: $light;
                    }

                    @media (max-width:767px) {
                        padding: 0.7vw 3vw;
                    }
                }
            }
        }
    }
}

.selected_ourlife {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;

    .sleceted_img {
        z-index: 5;
        max-width: 70%;
        min-width: 70%;
        max-height: 80%;
        min-height: 80%;
        position: sticky;
        object-fit: contain;

        @media (max-width:767px) {
            max-width: 90%;
            min-width: 90%;
            max-height: 70%;
            min-height: 70%;
        }
    }

    .close_icon {
        top: 3vw;
        z-index: 6;
        right: 5vw;
        width: 2vw;
        height: 2vw;
        cursor: pointer;
        position: absolute;

        @media (max-width:767px) {
            width: 5vw;
            height: 5vw;
        }
    }
}