// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.InterviewItem {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }
    }

    .c2 {
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            font-weight: 700;
            text-align: center;
        }

        .infos {
            display: flex;
            justify-content: space-between;

            @media (max-width:767px) {
                gap: 12vw;
                flex-direction: column;
            }

            .left {
                width: 65%;
                display: flex;
                flex-direction: column;

                @media (max-width:767px) {
                    width: 100%;
                }

                .img {
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 27vw;
                    min-height: 27vw;
                    object-fit: contain;
                    background-color: $secondary_light;
                    border: solid 1px $secondary_light;

                    @media (max-width:767px) {
                        max-height: 60vw;
                        min-height: 60vw;
                    }
                }

                .date {
                    color: $success;
                    font-weight: 600;
                }

                .link {
                    color: $dark;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: underline;
                }

                .desc {
                    opacity: 0.7;
                }
            }

            .right {
                width: 25%;
                display: flex;
                flex-direction: column;

                @media (max-width:767px) {
                    gap: 10vw;
                    width: 100%;
                }

                .info {
                    display: flex;
                    flex-direction: column;

                    .img {
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 10vw;
                        min-height: 10vw;
                        object-fit: cover;
                        background-color: $secondary_light;

                        @media (max-width:767px) {
                            max-height: 60vw;
                            min-height: 60vw;
                        }
                    }

                    .date {
                        color: $success;
                        font-weight: 600;
                    }

                    .link {
                        color: $dark;
                        font-weight: 600;
                        text-transform: uppercase;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}