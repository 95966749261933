.Contacts {
    background-color: #0A3969;

    .wrapper {
        display: flex;
        margin-top: 7vw;
        padding-bottom: 0;
        align-items: center;
        justify-content: center;

        @media (max-width:767px) {
            margin-top: 19vw;
            padding-bottom: 2vw;
        }

        .title {
            color: white;
            text-align: center;
        }
    }
}