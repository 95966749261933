// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Leaderships {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }

        .main_title {
            z-index: 2;
            color: $dark;
            font-size: 7vw;
            font-weight: 700;
            position: sticky;

            &:last-child {
                color: $light;
            }

            @media (max-width:767px) {
                font-size: 9.5vw;
            }
        }
    }

    .c2 {
        display: flex;
        flex-direction: column;

        .sub-title {
            color: $dark;
            font-weight: 600;
            text-transform: uppercase;
        }

        .selection {
            display: flex;
            align-items: center;
            justify-content: center;

            select {
                border: none;
                outline: none;
                cursor: pointer;
                border-bottom: solid 1px $dark;
            }
        }

        .athletes {
            display: grid;
            grid-template-columns: repeat(2, 2fr);

            @media (max-width:767px) {
                gap: 2vw;
                grid-template-columns: repeat(1, 1fr);
            }

            .athlete {
                display: flex;
                align-items: center;
                border-top: solid 1px $secondary_light;

                &:first-child,
                &:nth-child(2) {
                    border-top-color: transparent;
                }

                &:nth-child(2n) {
                    border-left: solid 1px $secondary_light;

                    @media (max-width:767px) {
                        border: none;
                    }
                }

                @media (max-width:767px) {
                    border: none;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .img {
                    max-width: 40%;
                    min-width: 40%;
                    border: solid 1px $secondary_light;

                    @media (max-width:767px) {
                        max-width: 100%;
                        min-width: 100%;
                    }
                }

                .texts {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .who {
                        opacity: 0.6;
                        text-transform: uppercase;
                    }

                    .at_name {
                        text-transform: uppercase;
                    }

                    .link {
                        color: $info;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}