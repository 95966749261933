// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$green: #4ab40b;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Competetions {
    .c1 {
        display: flex;
        min-height: 100vh;
        position: relative;
        padding-right: 5vw;
        padding-bottom: 5vw;
        flex-direction: column;
        justify-content: flex-end;

        @media (max-width:767px) {
            min-height: 350px;
        }

        .main_title {
            z-index: 2;
            width: 100%;
            color: $light;
            display: flex;
            font-size: 5.5vw;
            font-weight: 700;
            position: sticky;
            text-align: right;
            align-items: center;
            justify-content: space-between;

            &::before {
                width: 50%;
                height: 2px;
                content: "";
                margin-right: 2vw;
                background-color: $light;

                @media (max-width:767px) {
                    width: 25%;
                    height: 1px;
                }
            }

            @media (max-width:767px) {
                font-size: 8.5vw;
            }
        }
    }

    .c2 {
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            font-weight: 700;
            text-align: center;
        }

        .filter {
            display: flex;
            padding: 2vw 4vw;
            align-items: center;
            border-radius: 70px;
            -webkit-border-radius: 70px;
            -moz-border-radius: 70px;
            -ms-border-radius: 70px;
            -o-border-radius: 70px;
            background-color: $dodgerblue;

            @media (max-width:767px) {
                padding: 3vw;
                overflow-x: auto;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                width: calc(100% + 10vw);
            }

            .type_gender {
                display: flex;
                align-items: center;

                @media (max-width:767px) {
                    gap: 1.5vw;
                }

                .text {
                    color: $light;
                    cursor: pointer;
                }

                .badge {
                    width: 3vw;
                    height: 1.5vw;
                    display: flex;
                    cursor: pointer;
                    position: relative;
                    align-items: center;
                    border-radius: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    -ms-border-radius: 50px;
                    -o-border-radius: 50px;
                    transition: 0.2s linear;
                    -webkit-transition: 0.2s linear;
                    -moz-transition: 0.2s linear;
                    -ms-transition: 0.2s linear;
                    -o-transition: 0.2s linear;
                    background-color: $light;

                    &::before {
                        left: 0.2vw;
                        content: "";
                        width: 1.3vw;
                        height: 1.3vw;
                        position: absolute;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                        transition: 0.2s linear;
                        -webkit-transition: 0.2s linear;
                        -moz-transition: 0.2s linear;
                        -ms-transition: 0.2s linear;
                        -o-transition: 0.2s linear;
                        background-color: $success;

                        @media (max-width:767px) {
                            left: 1vw;
                            width: 3vw;
                            height: 3vw;
                        }
                    }

                    @media (max-width:767px) {
                        width: 8vw;
                        height: 4vw;
                    }
                }

                input[type="checkbox"] {
                    display: none;
                }

                input[type="checkbox"]:checked~.badge {
                    background-color: $success;

                    &::before {
                        background-color: $light;
                        transform: translateX(100%);
                        -webkit-transform: translateX(100%);
                        -moz-transform: translateX(100%);
                        -ms-transform: translateX(100%);
                        -o-transform: translateX(100%);
                    }
                }
            }

            .select {

                .css-13cymwt-control,
                .css-t3ipsp-control {
                    border: none;
                    outline: none;
                    cursor: pointer;
                    border-radius: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    -ms-border-radius: 50px;
                    -o-border-radius: 50px;
                    padding: 0.3vw 0.7vw;
                    background-color: $light;

                    @media (max-width:767px) {
                        width: 50vw;
                    }

                    .css-1fdsijx-ValueContainer {
                        padding-right: 3vw;

                        @media (max-width:767px) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        .colors {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:767px) {
                flex-wrap: wrap;
            }

            .text {
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    width: 1.5vw;
                    height: 0.5vw;
                    margin-right: 10px;
                    border-radius: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    -ms-border-radius: 50px;
                    -o-border-radius: 50px;
                    background-color: $dark;

                    @media (max-width:767px) {
                        width: 5vw;
                        height: 1.5vw;
                        margin-right: 5px;
                    }
                }
            }

            .info {
                &::before {
                    background-color: $info;
                }
            }

            .pink {
                &::before {
                    background-color: $pink;
                }
            }

            .green {
                &::before {
                    background-color: $green;
                }
            }

            .dodger {
                &::before {
                    background-color: $dodgerblue;
                }
            }
        }

        .cards {
            width: 100%;
            display: flex;
            flex-direction: column;

            .info_card {
                width: 100%;
                display: flex;
                overflow: hidden;
                padding-left: 2vw;
                align-items: center;
                justify-content: space-between;
                border: solid 1px $secondary_light;
                border-left: solid 0.5vw;

                @media (max-width:767px) {
                    gap: 2vw;
                    padding: 2vw;
                    flex-wrap: wrap;
                    border-left: solid 1.5vw;
                }

                .logo {
                    max-width: 3vw;
                    min-width: 3vw;

                    @media (max-width:767px) {
                        max-width: 10vw;
                        min-width: 10vw;
                        display: none;
                    }
                }

                .desc {
                    width: 60%;
                    font-weight: 700;

                    @media (max-width:767px) {
                        width: 50%;
                        font-weight: 400;
                        font-size: 2.8vw;
                    }
                }

                .time {
                    height: 7.5vw;
                    display: flex;
                    font-weight: 400;
                    align-items: center;
                    justify-content: center;
                    background-color: $secondary_light;

                    @media (max-width:767px) {
                        padding: 0;
                        height: auto;
                        font-size: 2.8vw;
                        background-color: transparent;
                    }
                }

                .link {
                    color: $info;
                    text-decoration: underline;

                    @media (max-width:767px) {
                        font-size: 2.8vw;
                    }
                }
            }

            .info {
                border-left-color: $info;
            }

            .pink {
                border-left-color: $pink;
            }

            .green {
                border-left-color: $green;
            }

            .dodger {
                border-left-color: $dodgerblue;
            }
        }
    }
}