// colors //

$dark: black;
$light: white;
$success: #a4cd00;

.Navbar {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    position: fixed;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    background-color: $dark;

    .navbar {
        display: flex;
        padding: 1vw 5vw;
        padding-top: 2vw;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        flex-direction: column;

        @media (max-width:767px) {
            padding: 2vw 3vw;
            padding-top: 4vw;
        }

        .top_navbar {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;

                @media (max-width:767px) {
                    gap: 3vw;
                }

                .logo {
                    width: 4.5vw;

                    @media (max-width:767px) {
                        width: 12vw;
                    }

                    .logo_img {
                        max-width: 100%;
                        min-width: 100%;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                        animation-duration: 10s;
                        background-color: $light;
                        animation-timing-function: linear;
                        animation-iteration-count: infinite;

                        &:hover {
                            animation-name: logo;
                        }
                    }

                    @keyframes logo {
                        100% {
                            transform: rotate(360deg);
                            -webkit-transform: rotate(360deg);
                            -moz-transform: rotate(360deg);
                            -ms-transform: rotate(360deg);
                            -o-transform: rotate(360deg);
                        }
                    }
                }

                .language {
                    display: flex;

                    .lang {
                        opacity: 0.8;
                        border: none;
                        color: $light;
                        cursor: pointer;
                        font-weight: 600;
                        background-color: transparent;

                        &:hover {
                            color: $dark;
                            background-color: $light;
                        }
                    }

                    .active_lang {
                        opacity: 1;
                        color: $success;
                    }
                }
            }

            .links {
                display: flex;
                transition: 0.4s;
                -webkit-transition: 0.4s;
                -moz-transition: 0.4s;
                -ms-transition: 0.4s;
                -o-transition: 0.4s;
                align-items: center;

                @media (max-width:767px) {
                    top: 0;
                    z-index: 3;
                    width: 100vw;
                    height: 100vh;
                    right: -100vw;
                    padding: 15vw 0;
                    position: absolute;
                    flex-direction: column;
                    background-color: $dark;
                    justify-content: space-between;
                }

                .link {
                    display: flex;
                    cursor: pointer;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    color: rgba(255, 255, 255, 0.794);

                    &:hover {
                        &::after {
                            width: 100%;
                        }

                        .link_bar {
                            padding-top: 3vw;
                            visibility: visible;
                        }
                    }

                    &::after {
                        width: 0;
                        height: 1px;
                        content: "";
                        bottom: -5px;
                        transition: 0.2s;
                        -webkit-transition: 0.2s;
                        -moz-transition: 0.2s;
                        -ms-transition: 0.2s;
                        -o-transition: 0.2s;
                        position: absolute;
                        background-color: $light;
                    }

                    .link_bar {
                        top: 0;
                        left: -5vw;
                        width: 38vw;
                        padding-top: 6vw;
                        visibility: hidden;
                        position: absolute;
                        transition: 0.2s linear;
                        -webkit-transition: 0.2s linear;
                        -moz-transition: 0.2s linear;
                        -ms-transition: 0.2s linear;

                        @media (max-width:767px) {
                            display: none;
                        }

                        .cont_link {
                            width: 100%;
                            gap: 1.2vw 2vw;
                            display: grid;
                            border-top-left-radius: 0;
                            border: 1px solid #d6fb29;
                            background-color: #354202d2;
                            grid-template-columns: repeat(2, 2fr);
                            filter: drop-shadow(5.5px 9.526px 3.5px rgba(0, 0, 0, 0.55));
                            background-image: linear-gradient(194deg, rgb(158, 178, 76) 0%, rgba(205, 230, 105, 0) 100%);
                        }

                        .bar-link {
                            color: $light;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .more_btn {
                    opacity: 0.8;
                    color: $light;
                    cursor: pointer;
                    border-radius: 70px;
                    -webkit-border-radius: 70px;
                    -moz-border-radius: 70px;
                    -ms-border-radius: 70px;
                    -o-border-radius: 70px;
                    padding: 0.4vw 1.5vw;
                    border: dashed 1px $light;
                    background-color: transparent;

                    &:hover {
                        color: $dark;
                        background-color: $light;
                    }

                    @media (max-width:767px) {
                        padding: 1vw 5vw;
                    }
                }

                .text {
                    @media (max-width:767px) {
                        font-size: 16px;
                    }
                }

                .socials {
                    gap: 6vw;
                    display: flex;
                    align-items: center;

                    @media (min-width:767px) {
                        display: none;
                    }

                    .icon-1 {
                        width: 5vw;
                        height: 5vw;
                        cursor: pointer;
                    }
                }

                .close_icon {
                    top: 5vw;
                    right: 5vw;
                    width: 4.5vw;
                    height: 4.5vw;
                    display: flex;
                    stroke: $success;
                    position: absolute;
                    align-items: center;
                    justify-content: center;

                    @media (min-width:767px) {
                        display: none;
                    }
                }
            }

            .show_menu {
                right: 0;
            }

            .submit_btn {
                color: $success;
                cursor: pointer;
                border-radius: 70px;
                -webkit-border-radius: 70px;
                -moz-border-radius: 70px;
                -ms-border-radius: 70px;
                -o-border-radius: 70px;
                padding: 0.8vw 2.5vw;
                border: dashed 1px $success;
                background-color: transparent;

                &:hover {
                    color: $dark;
                    background-color: $success;
                }
            }

            .menu_icon {
                width: 5vw;
                height: 5vw;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (min-width:767px) {
                    display: none;
                }
            }
        }

        .bottom_navbar {
            z-index: 0;
            display: flex;
            position: sticky;
            align-items: center;
            justify-content: flex-end;

            @media (max-width:767px) {
                display: none;
            }

            .socials {
                display: flex;
                padding: 0 2.5vw;
                align-items: center;

                .icon-1 {
                    fill: $light;
                    opacity: 0.8;
                    cursor: pointer;
                    transition: 0.3s;
                    -webkit-transition: 0.3s;
                    -moz-transition: 0.3s;
                    -ms-transition: 0.3s;
                    -o-transition: 0.3s;

                    &:hover {
                        transform: rotate(360deg) scale(1.3);
                        -webkit-transform: rotate(360deg) scale(1.3);
                        -moz-transform: rotate(360deg) scale(1.3);
                        -ms-transform: rotate(360deg) scale(1.3);
                        -o-transform: rotate(360deg) scale(1.3);
                    }
                }
            }
        }
    }

    .menu_bar {
        left: 0;
        z-index: 5;
        top: -100vh;
        width: 100vw;
        height: 100vh;
        display: flex;
        position: fixed;
        overflow-y: auto;
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        padding: 7vw 10vw;
        align-items: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.834);
        backdrop-filter: blur(13px);

        @media (max-width:767px) {
            padding: 13vw 5vw;
        }

        .close_icon {
            top: 3vw;
            right: 4vw;
            z-index: 4;
            width: 2.3vw;
            height: 2.3vw;
            display: flex;
            cursor: pointer;
            stroke: $success;
            transition: 0.2s;
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -ms-transition: 0.2s;
            -o-transition: 0.2s;
            position: absolute;
            align-items: center;
            justify-content: center;

            &:hover {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
            }

            @media (max-width:767px) {
                top: 5vw;
                right: 6vw;
                width: 4.5vw;
                height: 4.5vw;
            }
        }

        .searching {
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            input {
                width: 100%;
                z-index: 10;
                border: none;
                outline: none;
                color: $light;
                position: sticky;
                background-color: transparent;
                border-bottom: solid 1px $success;
            }

            .serch_bar {
                left: 0;
                top: 4.5vw;
                z-index: 10;
                width: 100%;
                display: flex;
                overflow: auto;
                max-height: 50vh;
                position: absolute;
                flex-direction: column;
                background-color: white;

                @media (max-width:767px) {
                    top: 11vw;
                }

                .search_link {
                    display: flex;
                    color: black;
                    align-items: center;
                    text-decoration: none;

                    .icn {
                        width: 2vw;
                        display: flex;
                        fill: $success;
                        stroke: $success;
                        align-items: center;
                        justify-content: center;

                        @media (max-width:767px) {
                            width: 5vw;
                        }
                    }
                }
            }

            .show_search_bar {
                display: flex;
            }
        }

        .links {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 3fr);

            @media (max-width:767px) {
                grid-template-columns: repeat(2, 2fr);
            }

            .tool {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .tool-title {
                    color: $success;
                    font-weight: 700;
                }

                .tool-link {
                    opacity: 0.8;
                    color: $light;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .show_menu_bar {
        top: 0;
    }
}

.scrolling_navbar {
    backdrop-filter: blur(15px);
    background-color: rgba(0, 0, 0, 0.487);

    .navbar {
        padding: 0.5vw 5vw;

        @media (max-width:767px) {
            padding: 1vw 3vw;
            padding-bottom: 0;
        }

        .bottom_navbar {
            display: none;
        }
    }

    .menu_bar {
        background-color: rgba(0, 0, 0, 0.943);
    }
}