// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);

.Records {
    .wrapper {
        display: flex;
        padding-top: 12vw;
        flex-direction: column;

        @media (max-width:767px) {
            padding-top: 25vw;
        }

        .sub-title {
            font-weight: 600;
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(3, 3fr);

            @media (max-width:767px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .record {
                display: flex;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                text-decoration: none;
                flex-direction: column;
                border: solid 1px rgb(211, 211, 211);

                &:hover {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                }

                .icon {
                    width: 3vw;
                    display: flex;
                    fill: $success;
                    align-items: center;
                    justify-content: center;

                    @media (max-width:767px) {
                        width: 9vw;
                    }
                }

                .record_name {
                    color: $dark;
                    font-weight: 500;
                }

                .desc {
                    color: $dark;
                    opacity: 0.8;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}