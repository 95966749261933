// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Doping {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }
    }

    .c2 {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 700;
            text-align: center;
        }

        .infos {
            display: flex;
            flex-direction: column;
        }

        .cards {
            display: flex;
            flex-direction: column;

            .file_card {
                display: flex;
                overflow: hidden;
                justify-content: space-between;
                background-color: $secondary_light;

                .img {
                    width: 9vw;
                    height: 6vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(247, 247, 247);

                    @media (max-width:767px) {
                        width: 20vw;
                        height: auto;
                    }

                    img {
                        max-width: 35%;
                        min-width: 35%;

                        @media (max-width:767px) {
                            max-width: 50%;
                            min-width: 50%;
                        }
                    }
                }

                .desc {
                    width: 100%;
                    font-weight: 700;
                }

                .download {
                    height: 6vw;
                    display: flex;
                    padding-top: 0;
                    padding-bottom: 0;
                    align-items: center;
                    background-color: rgb(247, 247, 247);

                    @media (max-width:767px) {
                        height: auto;
                        padding: 3vw;
                    }

                    .icon {
                        fill: $dark;
                        opacity: 0.4;
                        width: 1.5vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media (max-width:767px) {
                            width: 3.5vw;
                        }
                    }

                    .link {
                        color: $success;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}