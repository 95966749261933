// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Diving {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }

        .main_title {
            z-index: 2;
            color: $dark;
            font-size: 7vw;
            font-weight: 700;
            position: sticky;

            &:last-child {
                color: $light;
            }

            @media (max-width:767px) {
                font-size: 9.5vw;
            }
        }
    }

    .c2 {
        display: flex;
        flex-direction: column;

        .selection {
            display: flex;
            align-items: center;
            justify-content: center;

            select {
                border: none;
                outline: none;
                cursor: pointer;
                background-color: transparent;
                border-bottom: solid 1px $succes-light;
            }
        }

        .categories {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:767px) {
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }

            .category {
                display: flex;
                cursor: pointer;
                align-items: center;

                @media (max-width:767px) {
                    justify-content: center;
                    border: solid 1px $success;
                    background-color: $success;
                }

                .text {
                    position: relative;

                    &::before {
                        left: 0;
                        width: 0%;
                        top: -0.5vw;
                        height: 2px;
                        content: "";
                        transition: 0.2s;
                        -webkit-transition: 0.2s;
                        -moz-transition: 0.2s;
                        -ms-transition: 0.2s;
                        -o-transition: 0.2s;
                        position: absolute;
                        background-color: $success;

                        @media (max-width:767px) {
                            height: 1px;
                        }
                    }
                }

                .icon {
                    width: 3.5vw;
                    height: 3.5vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    fill: rgba(0, 0, 0, 0.41);
                    stroke: rgba(0, 0, 0, 0.41);

                    @media (max-width:767px) {
                        width: 9vw;
                        height: 9vw;
                        fill: $dark;
                        stroke: $dark;
                    }
                }
            }

            .active_category {
                @media (max-width:767px) {
                    border-color: $dark;
                }

                .text {
                    position: relative;

                    &::before {
                        width: 100%;
                    }
                }

                .icon {
                    fill: $success;
                    stroke: $success;

                    @media (max-width:767px) {
                        fill: $dark;
                        stroke: $dark;
                    }
                }
            }
        }

        .BranchInformations {
            display: flex;
            flex-direction: column;

            .infos {
                display: flex;
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
                flex-direction: column;
                border-bottom: solid 1px rgb(217, 217, 217);
            }
        }
    }
}