// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Interview {
    .wrapper {
        display: flex;
        flex-direction: column;

        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:767px) {
                gap: 4vw;
                margin-top: 6vw;
                flex-direction: column;
                align-items: flex-start;
            }

            .img {
                max-width: 35%;
                min-width: 35%;
                max-height: 20vw;
                min-height: 20vw;
                object-fit: cover;
                border: solid 1px rgb(227, 227, 227);

                @media (max-width:767px) {
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            .texts {
                width: 60%;
                display: flex;
                flex-direction: column;

                @media (max-width:767px) {
                    width: 100%;
                }

                .date {
                    color: $success;
                    font-weight: 600;
                }

                .link {
                    color: $dark;
                    text-transform: uppercase;
                    text-decoration: underline;
                }

                .desc {
                    opacity: 0.6;
                    overflow: hidden;
                    line-height: 1.5vw;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;

                    @media (max-width:767px) {
                        line-height: 4.5vw;
                    }
                }
            }
        }
    }
}