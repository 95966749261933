// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);

.About {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }
    }

    .c2 {
        display: flex;
        flex-direction: column;

        .contacts {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:767px) {
                gap: 7vw;
                align-items: flex-start;
                flex-direction: column-reverse;
            }

            .infos {
                width: 45%;
                display: flex;
                flex-direction: column;
                border: solid 1px $success;

                @media (max-width:767px) {
                    width: 100%;
                }

                .selection {
                    border: none;
                    outline: none;
                    cursor: pointer;
                    padding-bottom: 3px;
                    background-color: transparent;
                    border-bottom: solid 1px rgba(2, 2, 2, 0.492);

                    option {
                        color: $dark;
                    }
                }

                .text {
                    opacity: 0.8;
                    color: $dark;
                }

                .cont {
                    color: $dark;
                    font-weight: 600;
                }

                .email {
                    a {
                        color: $success;
                    }
                }

                .tel {
                    a {
                        color: $dark;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .icons {
                    display: flex;
                    align-items: center;

                    @media (max-width:767px) {
                        gap: 10vw;
                    }

                    .icon {
                        width: 2.5vw;
                        height: 2.5vw;
                        fill: $success;
                        cursor: pointer;
                        stroke: $success;
                        transition: 0.3s;
                        -webkit-transition: 0.3s;
                        -moz-transition: 0.3s;
                        -ms-transition: 0.3s;
                        -o-transition: 0.3s;

                        &:hover {
                            transform: rotate(360deg) scale(1.3);
                            -webkit-transform: rotate(360deg) scale(1.3);
                            -moz-transform: rotate(360deg) scale(1.3);
                            -ms-transform: rotate(360deg) scale(1.3);
                            -o-transform: rotate(360deg) scale(1.3);
                        }

                        @media (max-width:767px) {
                            width: 6vw;
                            height: 6vw;
                        }
                    }
                }
            }

            .forms {
                width: 45%;
                display: flex;
                flex-direction: column;

                @media (max-width:767px) {
                    width: 100%;
                }

                .input {
                    resize: none;
                    outline: none;
                    border: solid 1px $success;

                    &:focus {
                        border-color: $dark;
                    }
                }

                .send_btn {
                    border: none;
                    color: $light;
                    font-weight: 600;
                    background-color: $success;
                }
            }
        }

        .structures {
            display: grid;
            grid-template-columns: repeat(4, 4fr);

            @media (max-width:767px) {
                grid-template-columns: repeat(2, 2fr);
            }

            .structure {
                display: flex;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                align-items: center;
                text-decoration: none;
                flex-direction: column;
                border: solid 1px #a4cd006e;

                &:hover {
                    border-color: $success;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                }

                .text {
                    color: $dark;
                    font-weight: 600;
                }

                .min-text {
                    color: $dark;
                    opacity: 0.7;
                    text-align: center;
                }

                .icon {
                    width: 3vw;
                    height: 3vw;
                    display: flex;
                    fill: $success;
                    stroke: $success;
                    align-items: center;
                    justify-content: center;

                    @media (max-width:767px) {
                        width: 10vw;
                        height: 10vw;
                    }
                }
            }
        }
    }
}