.Partners {
    .wrapper {
        display: flex;
        flex-direction: column;

        .sub-title {
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;

            @media (max-width:767px) {
                margin-top: 5vw;
            }
        }

        .mySwiper {
            width: 100%;
            padding-top: 2vw;

            .img {
                width: 100%;
                height: 13vw;
                display: flex;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                align-items: center;
                justify-content: center;
                border: solid 1px transparent;

                @media (max-width:767px) {
                    height: 22vw;
                    border-color: rgb(198, 198, 198);
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                }

                &:hover {
                    transform: translateY(-10px);
                    -webkit-transform: translateY(-10px);
                    -moz-transform: translateY(-10px);
                    -ms-transform: translateY(-10px);
                    -o-transform: translateY(-10px);
                    border-color: rgb(198, 198, 198);
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                }

                .partner_img {
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 100%;
                    min-height: 100%;
                    object-fit: contain;
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                left: 0;
                width: 3vw;
                height: 3vw;
                display: flex;
                margin-top: 0;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                align-items: center;
                justify-content: center;
                background-color: white;
                border: solid 1px #9D9D9D;

                &::after {
                    font-size: 1.5vw;
                    color: #9D9D9D;

                    @media (max-width:767px) {
                        font-size: 2.8vw;
                    }
                }

                @media (max-width:767px) {
                    width: 6vw;
                    height: 6vw;
                    margin-top: -1vw;
                }
            }

            .swiper-button-next {
                right: 0;
                left: auto;
            }
        }
    }
}