// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Coaches {
    display: flex;
    align-items: center;
    flex-direction: column;

    .filter {
        display: flex;
        padding: 2vw 4vw;
        align-items: center;
        border-radius: 70px;
        -webkit-border-radius: 70px;
        -moz-border-radius: 70px;
        -ms-border-radius: 70px;
        -o-border-radius: 70px;
        background-color: $dodgerblue;

        @media (max-width:767px) {
            flex-wrap: wrap;
            padding: 3vw 5vw;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            width: calc(100% + 10vw);
            justify-content: space-between;
        }

        .type_gender {
            display: flex;
            align-items: center;

            @media (max-width:767px) {
                gap: 1.5vw;
            }

            .text {
                color: $light;
                cursor: pointer;
            }

            .badge {
                width: 3vw;
                height: 1.5vw;
                display: flex;
                cursor: pointer;
                position: relative;
                align-items: center;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                transition: 0.2s linear;
                -webkit-transition: 0.2s linear;
                -moz-transition: 0.2s linear;
                -ms-transition: 0.2s linear;
                -o-transition: 0.2s linear;
                background-color: $light;

                &::before {
                    left: 0.2vw;
                    content: "";
                    width: 1.3vw;
                    height: 1.3vw;
                    position: absolute;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    transition: 0.2s linear;
                    -webkit-transition: 0.2s linear;
                    -moz-transition: 0.2s linear;
                    -ms-transition: 0.2s linear;
                    -o-transition: 0.2s linear;
                    background-color: $success;

                    @media (max-width:767px) {
                        left: 1vw;
                        width: 3vw;
                        height: 3vw;
                    }
                }

                @media (max-width:767px) {
                    width: 8vw;
                    height: 4vw;
                }
            }

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"]:checked~.badge {
                background-color: $success;

                &::before {
                    background-color: $light;
                    transform: translateX(100%);
                    -webkit-transform: translateX(100%);
                    -moz-transform: translateX(100%);
                    -ms-transform: translateX(100%);
                    -o-transform: translateX(100%);
                }
            }
        }

        .select {

            .css-13cymwt-control,
            .css-t3ipsp-control {
                border: none;
                outline: none;
                cursor: pointer;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                padding: 0.3vw 0.7vw;
                background-color: $light;

                @media (max-width:767px) {
                    width: 45vw;
                }

                .css-1fdsijx-ValueContainer {
                    padding-right: 3vw;

                    @media (max-width:767px) {
                        padding-right: 0;
                    }
                }
            }
        }

        .searching {
            width: 20vw;
            display: flex;
            position: relative;
            align-items: center;

            @media (max-width:767px) {
                width: 100%;
            }

            .search_input {
                width: 100%;
                outline: none;
                padding: 0.7vw 1vw;
                border-radius: 60px;
                padding-right: 3.5vw;
                -webkit-border-radius: 60px;
                -moz-border-radius: 60px;
                -ms-border-radius: 60px;
                -o-border-radius: 60px;
                border: solid 1px $light;

                &:focus {
                    border-color: $dark;
                }

                @media (max-width:767px) {
                    padding: 2.2vw 3.5vw;
                    padding-right: 9.5vw;
                }
            }

            .icon {
                right: 1vw;
                width: 1.8vw;
                border: none;
                display: flex;
                fill: $success;
                cursor: pointer;
                stroke: $success;
                position: absolute;
                align-items: center;
                justify-content: center;
                background-color: transparent;

                @media (max-width:767px) {
                    width: 4.5vw;
                    right: 3.5vw;
                }
            }
        }
    }

    .athletes {
        display: grid;
        grid-template-columns: repeat(2, 2fr);

        @media (max-width:767px) {
            gap: 2vw;
            grid-template-columns: repeat(1, 1fr);
        }

        .athlete {
            display: flex;
            align-items: center;
            border-top: solid 1px $secondary_light;

            &:first-child,
            &:nth-child(2) {
                border-top-color: transparent;
            }

            &:nth-child(2n) {
                border-left: solid 1px $secondary_light;

                @media (max-width:767px) {
                    border: none;
                }
            }

            @media (max-width:767px) {
                border: none;
                flex-direction: column;
                align-items: flex-start;
            }

            .img {
                max-width: 40%;
                min-width: 40%;
                background-color: $secondary_light;
                border: solid 1px $secondary_light;

                @media (max-width:767px) {
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            .texts {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .at_name {
                    text-transform: uppercase;
                }

                .link {
                    color: $info;
                    text-decoration: underline;
                }
            }
        }
    }
}