// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Tournaments {
    display: flex;
    align-items: center;
    flex-direction: column;

    .filter {
        display: flex;
        padding: 2vw 4vw;
        align-items: center;
        border-radius: 70px;
        -webkit-border-radius: 70px;
        -moz-border-radius: 70px;
        -ms-border-radius: 70px;
        -o-border-radius: 70px;
        background-color: $dodgerblue;

        @media (max-width:767px) {
            padding: 3vw;
            overflow-x: auto;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            width: calc(100% + 10vw);
        }

        .type_gender {
            display: flex;
            align-items: center;

            @media (max-width:767px) {
                gap: 1.5vw;
            }

            .text {
                color: $light;
                cursor: pointer;
            }

            .badge {
                width: 3vw;
                height: 1.5vw;
                display: flex;
                cursor: pointer;
                position: relative;
                align-items: center;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                transition: 0.2s linear;
                -webkit-transition: 0.2s linear;
                -moz-transition: 0.2s linear;
                -ms-transition: 0.2s linear;
                -o-transition: 0.2s linear;
                background-color: $light;

                &::before {
                    left: 0.2vw;
                    content: "";
                    width: 1.3vw;
                    height: 1.3vw;
                    position: absolute;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    transition: 0.2s linear;
                    -webkit-transition: 0.2s linear;
                    -moz-transition: 0.2s linear;
                    -ms-transition: 0.2s linear;
                    -o-transition: 0.2s linear;
                    background-color: $success;

                    @media (max-width:767px) {
                        left: 1vw;
                        width: 3vw;
                        height: 3vw;
                    }
                }

                @media (max-width:767px) {
                    width: 8vw;
                    height: 4vw;
                }
            }

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"]:checked~.badge {
                background-color: $success;

                &::before {
                    background-color: $light;
                    transform: translateX(100%);
                    -webkit-transform: translateX(100%);
                    -moz-transform: translateX(100%);
                    -ms-transform: translateX(100%);
                    -o-transform: translateX(100%);
                }
            }
        }

        .select {

            .css-13cymwt-control,
            .css-t3ipsp-control {
                border: none;
                outline: none;
                cursor: pointer;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                padding: 0.3vw 0.7vw;
                background-color: $light;

                @media (max-width:767px) {
                    width: 50vw;
                }

                .css-1fdsijx-ValueContainer {
                    padding-right: 3vw;

                    @media (max-width:767px) {
                        padding-right: 0;
                    }
                }
            }
        }

        .search_icon {
            width: 2.7vw;
            fill: $light;
            display: flex;
            stroke: $light;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            @media (max-width:767px) {
                width: 7vw;
            }
        }
    }

    .cards {
        width: 100%;
        display: flex;
        flex-direction: column;

        .info_card {
            width: 100%;
            display: flex;
            overflow: hidden;
            padding-left: 2vw;
            align-items: center;
            justify-content: space-between;
            border: solid 1px $secondary_light;
            border-left: solid 0.5vw #4ab40b;

            @media (max-width:767px) {
                padding: 2vw;
                flex-wrap: wrap;
                border-left: solid 1.5vw #4ab40b;
            }

            .logo {
                max-width: 3vw;
                min-width: 3vw;

                @media(max-width:767px) {
                    display: none;
                    max-width: 10vw;
                    min-width: 10vw;
                }
            }

            .desc {
                width: 60%;
                font-weight: 700;

                @media (max-width:767px) {
                    width: 55%;
                    font-weight: 400;
                }
            }

            .time {
                height: 7.5vw;
                display: flex;
                font-weight: 400;
                align-items: center;
                justify-content: center;
                background-color: $secondary_light;

                @media (max-width:767px) {
                    padding: 0;
                    height: auto;
                    background-color: transparent;
                }
            }

            .link {
                color: $info;
                text-decoration: underline;
            }
        }
    }
}