// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Pools {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }
    }

    .c2 {
        display: flex;
        align-items: center;
        flex-direction: column;

        .sub-title {
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 2fr);

        @media (max-width:767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .branch {
            width: 100%;
            display: grid;
            align-items: center;
            text-decoration: none;
            background-color: $secondary_light;
            grid-template-columns: repeat(2, 2fr);

            @media (max-width:767px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .img {
                max-width: 100%;
                min-width: 100%;
                max-height: 10vw;
                min-height: 10vw;
                object-fit: cover;
                background-color: $light;

                @media (max-width:767px) {
                    max-height: 45vw;
                    min-height: 45vw;
                }
            }

            .texts {
                display: flex;
                flex-direction: column;

                .text {
                    color: $dark;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .min-text {
                    color: $dark;
                }
            }
        }
    }

    .item_infos {
        width: 100%;
        display: flex;
        align-items: center;

        @media (max-width:767px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .infos {
            display: flex;
            flex-direction: column;
        }

        .pool_img {
            max-width: 60%;
            min-width: 60%;
            max-height: 40vw;
            min-height: 40vw;
            object-fit: cover;
            background-color: $secondary_light;

            @media (max-width:767px) {
                max-width: 100%;
                min-width: 100%;
                max-height: 70vw;
                min-height: 70vw;
            }
        }
    }

    .desc {
        width: 100%;
        text-align: left;
    }

    .location {
        max-width: 100%;
        min-width: 100%;
        overflow: hidden;
        border: solid 1px rgb(183, 183, 183);
    }
}