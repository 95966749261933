// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Videos {
    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 2fr);

        @media (max-width:767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .video {
            display: flex;
            cursor: pointer;
            margin-bottom: 3vw;
            position: relative;
            align-items: center;
            justify-content: center;

            &:hover {
                .img {
                    filter: brightness(0.8);
                    -webkit-filter: brightness(0.8);
                }

                .icon {
                    opacity: 1;
                }
            }

            @media (max-width:767px) {
                margin-top: 6vw;
                margin-bottom: 9vw;
            }

            .img {
                max-width: 100%;
                min-width: 100%;
                max-height: 25vw;
                min-height: 25vw;
                object-fit: cover;
                border: solid 1px rgb(219, 219, 219);

                @media (max-width:767px) {
                    max-height: 65vw;
                    min-height: 65vw;
                }
            }

            .icon {
                width: 5vw;
                opacity: 0.8;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;

                @media (max-width:767px) {
                    width: 12vw;
                }
            }

            .texts {
                width: 70%;
                left: -2vw;
                bottom: -3vw;
                display: flex;
                position: absolute;
                flex-direction: column;
                background-color: #05538fc5;

                @media (max-width:767px) {
                    left: 0;
                    width: 100%;
                    bottom: -9vw;
                }

                .badge {
                    width: 3vw;
                    height: 0.6vw;
                    border-radius: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    -ms-border-radius: 50px;
                    -o-border-radius: 50px;
                    background-color: $success;

                    @media (max-width:767px) {
                        width: 8vw;
                        height: 1.5vw;
                    }
                }

                .text {
                    color: $light;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }
    }

    .c2 {
        display: flex;
        padding-top: 0;
        flex-direction: column;
    }
}

.video-container {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.718);

    .close-icon {
        top: 3vw;
        width: 2vw;
        right: 4vw;
        fill: $light;
        stroke: $light;
        cursor: pointer;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        position: absolute;

        &:hover {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }

        @media (max-width:767px) {
            top: 5vw;
            right: 6vw;
            width: 4.5vw;
            height: 4.5vw;
        }
    }

    video {
        max-width: 80%;
        max-height: 80vh;
        min-height: 80vh;
        object-fit: contain;
    }
}