$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);

.KidsFederation {
    background-color: $primary-light;

    &::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 3vw;
        content: "";
        position: absolute;
        background: linear-gradient(180deg, rgb(255, 255, 255), rgba(255, 255, 255, 0));

        @media (max-width:767px) {
            height: 5vw;
        }
    }

    .kids_img {
        max-width: 100%;
        min-width: 100%;
    }

    .wrapper {
        display: flex;
        padding-top: 2vw;
        flex-direction: column;

        .sub-title {
            opacity: 0.8;
            color: $light;
            text-align: center;
        }

        .infos {
            gap: 4vw;
            display: grid;
            grid-template-columns: repeat(3, 3fr);

            @media (max-width:767px) {
                gap: 9vw;
                grid-template-columns: repeat(1, 1fr);
            }

            .info {
                display: flex;
                cursor: pointer;
                overflow: hidden;
                position: relative;
                align-items: center;
                text-decoration: none;
                flex-direction: column;
                backdrop-filter: blur(10px);
                justify-content: space-between;
                background-color: rgba(255, 255, 255, 0.212);
                border-top: solid 1px rgba(255, 255, 255, 0.525);

                &:hover {
                    &::before {
                        width: 50vw;
                        height: 50vw;

                        @media (max-width:767px) {
                            width: 120vw;
                            height: 120vw;
                        }
                    }

                    .big-text,
                    .desc {
                        color: $dark;
                    }

                    .icon {
                        fill: $dark;
                    }
                }

                &::before {
                    width: 0;
                    height: 0;
                    top: -15vw;
                    left: -15vw;
                    content: "";
                    transition: 0.4s;
                    -webkit-transition: 0.4s;
                    -moz-transition: 0.4s;
                    -ms-transition: 0.4s;
                    -o-transition: 0.4s;
                    position: absolute;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    background-color: $light;

                    @media (max-width:767px) {
                        top: -35vw;
                        left: -15vw;
                    }
                }

                .icon {
                    z-index: 3;
                    fill: $light;
                    width: 3.5vw;
                    height: 3.5vw;
                    display: flex;
                    position: sticky;
                    transition: 0.4s;
                    -webkit-transition: 0.4s;
                    -moz-transition: 0.4s;
                    -ms-transition: 0.4s;
                    -o-transition: 0.4s;
                    align-items: center;
                    justify-content: center;

                    @media (max-width:767px) {
                        width: 10vw;
                        height: 10vw;
                    }
                }

                .big-text {
                    z-index: 3;
                    color: $light;
                    position: sticky;
                    font-weight: 600;
                    transition: 0.4s;
                    -webkit-transition: 0.4s;
                    -moz-transition: 0.4s;
                    -ms-transition: 0.4s;
                    -o-transition: 0.4s;
                    margin-top: -0.5vw;
                }

                .desc {
                    z-index: 3;
                    opacity: 0.7;
                    color: $light;
                    position: sticky;
                    overflow: hidden;
                    transition: 0.4s;
                    -webkit-transition: 0.4s;
                    -moz-transition: 0.4s;
                    -ms-transition: 0.4s;
                    -o-transition: 0.4s;
                    text-align: center;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }

                .explore {
                    z-index: 3;
                    cursor: pointer;
                    color: $success;
                    position: sticky;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }
    }
}

.KidsModal {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;

    .child {
        width: 50vw;
        z-index: 10;
        overflow: auto;
        max-height: 70vh;
        position: absolute;
        background-color: white;

        @media (max-width:767px) {
            width: 80vw;
        }

        .icon {
            top: 1vw;
            right: 1vw;
            width: 1.5vw;
            display: flex;
            fill: $success;
            cursor: pointer;
            stroke: $success;
            position: absolute;
            align-items: center;
            justify-content: center;

            @media (max-width:767px) {
                top: 2vw;
                right: 2vw;
                width: 4vw;
            }
        }

        .name {
            font-weight: 700;
            text-align: center;
        }

        .big-text {
            text-align: justify;
        }
    }
}