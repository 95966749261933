// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);

.NewItem {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }
    }

    .c2 {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 700;
            text-align: center;
        }

        .date {
            color: $success;
            font-weight: 600;
        }

        .big-text {
            color: $primary;
            font-weight: 600;
            text-transform: uppercase;
        }

        .images {
            display: grid;
            grid-template-columns: repeat(2, 2fr);

            @media (max-width:767px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .img {
            max-width: 100%;
            min-width: 100%;
            min-height: 35vw;
            max-height: 35vw;
            object-fit: contain;
            background-color: $succes-light;

            @media (max-width:767px) {
                min-height: 65vw;
                max-height: 65vw;
            }
        }
    }
}