// colors //

$dark: black;
$light: white;
$pink: #d628fb;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.Images {
    .wrapper {
        display: grid;
        grid-template-columns: repeat(3, 3fr);

        @media (max-width:767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .info {
            display: flex;
            position: relative;
            margin-bottom: 3vw;
            align-items: center;
            text-decoration: none;

            @media (max-width:767px) {
                margin-top: 5vw;
                margin-bottom: 9vw;
            }

            .img {
                max-width: 100%;
                min-width: 100%;
                max-height: 17vw;
                min-height: 17vw;
                object-fit: cover;

                @media (max-width:767px) {
                    max-height: 60vw;
                    min-height: 60vw;
                }
            }

            .texts {
                width: 100%;
                bottom: -3vw;
                display: flex;
                position: absolute;
                flex-direction: column;
                background-color: #05538fc5;

                @media (max-width:767px) {
                    bottom: -9vw;
                }

                .badge {
                    width: 3vw;
                    height: 0.6vw;
                    border-radius: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    -ms-border-radius: 50px;
                    -o-border-radius: 50px;
                    background-color: $success;

                    @media (max-width:767px) {
                        width: 8vw;
                        height: 1.5vw;
                    }
                }

                .text {
                    color: $light;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }
    }

    .c2 {
        display: flex;
        padding-top: 0;
        flex-direction: column;
    }
}