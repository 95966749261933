// colors //

$dark: black;
$light: white;
$success: #a4cd00;
$primary: #021931;
$dodgerblue: #3BA2D7;
$succes-light: #E9EFD7;
$primary-light: #2C3546;
$info: rgb(9, 116, 222);
$secondary_light: rgb(242, 242, 242);

.CompetetionItem {
    .c1 {
        display: flex;
        min-height: 100vh;
        padding-top: 12vw;
        position: relative;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            min-height: 350px;
            padding-top: 18vw;
        }

        .main_title {
            z-index: 2;
            color: $dark;
            font-size: 7vw;
            font-weight: 700;
            position: sticky;

            &:last-child {
                color: $light;
            }

            @media (max-width:767px) {
                font-size: 9.5vw;
            }
        }
    }

    .c2 {
        display: flex;
        flex-direction: column;

        .sub-title {
            color: $dark;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
        }

        .desc {
            text-align: justify;
        }

        .info_card {
            display: flex;
            position: relative;
            flex-direction: column;
            background-color: #e6ecf0;

            @media (max-width:767px) {
                margin-top: 7vw;
            }

            .logo {
                left: 4vw;
                top: -3vw;
                width: 10vw;
                position: absolute;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;

                @media (max-width:767px) {
                    top: -8vw;
                    left: 8vw;
                    width: 20vw;
                }
            }

            .an-text {
                color: $dark;
                font-weight: 700;
                margin-left: 15vw;
                text-transform: uppercase;

                @media (max-width:767px) {
                    margin-left: 0;
                    margin-top: 13vw;
                    margin-bottom: 0;
                }
            }

            .tool {
                display: flex;
                align-items: center;

                @media (max-width:767px) {
                    gap: 1vw;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .text {
                    color: $dark;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .left {
                    width: 35%;
                    opacity: 0.6;
                    display: flex;
                    justify-content: flex-end;

                    @media (max-width:767px) {
                        width: 100%;
                        margin-top: 4vw;
                        justify-content: flex-start;
                    }
                }

                .right {
                    width: 65%;

                    @media (max-width:767px) {
                        width: 100%;
                    }
                }

                .link {
                    color: $info;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        .page_5 {
            display: flex;
            flex-direction: column;

            .file_card {
                display: flex;
                overflow: hidden;
                // align-items: center;
                justify-content: space-between;
                background-color: $secondary_light;

                .img {
                    width: 9vw;
                    height: 6vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(247, 247, 247);

                    @media (max-width:767px) {
                        width: 20vw;
                        height: auto;
                    }

                    img {
                        max-width: 35%;
                        min-width: 35%;

                        @media (max-width:767px) {
                            max-width: 50%;
                            min-width: 50%;
                        }
                    }
                }

                .desc {
                    width: 100%;
                    font-weight: 700;
                }

                .download {
                    height: 6vw;
                    display: flex;
                    padding-top: 0;
                    padding-bottom: 0;
                    align-items: center;
                    background-color: rgb(247, 247, 247);

                    @media (max-width:767px) {
                        height: auto;
                        padding: 3vw;
                    }

                    .icon {
                        fill: $dark;
                        opacity: 0.4;
                        width: 1.5vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media (max-width:767px) {
                            width: 3.5vw;
                        }
                    }

                    .link {
                        color: $success;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}